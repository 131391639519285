.cookie-confirmation {
  display: none;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08);
  font-family: $fontHelvetica;
  transform: translate3d(-50%,0,0);
  background: rgba(249,250,250,.95);
  border: 0;
  line-height: 18px;
  padding: 20px;
  position: fixed;
  text-align: left;
  text-shadow: none;
  top: auto;
  z-index: 100000000;
  bottom: 15px;
  left: 50%;
  max-width: 580px;

  @media (max-width: 767px) {
    bottom: 0;
    left: 0;
    transform: none;
    max-width: 100%;
    border-radius: 0;
  }

  &.is-active {
    display: block;
  }

  .cookie-confirmation-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-font-smoothing: antialiased;
  }

  .cookie-confirmation-text {
    font-size: 14.5px;
    line-height: 21px;
    color: #33475b;
    text-align: left;
    font-weight: 500;
    margin: 0;
    padding-right: 20px;

    a {
      color: $colorAccent1;
    }
  }

  .cookie-confirmation-buttons-area {

  }

  .confirmation-button {
    margin: 0;
    font-size: 14.5px;
    color: #fff;
    font-weight: 500;
    background: $colorAccent1;
    border: 0;
    border-radius: 4px;
    padding: 7px 16px;

  }
}
