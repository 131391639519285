/*
Icons Menu
*/

.icons {
  position: fixed;
  right: 85px;
  top: 0;
  margin: 14px;
  z-index: 100;
  mix-blend-mode: exclusion;
  width: 115px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 23px;
  align-items: center;

  @media (max-width: 1400px) {
    margin: 11px;
    right: 90px;
    width: 110px;
  }

  @media (max-width: 768px) {
    right: 70px;
    width: 50px;
  }

  @media (max-width: 600px) {
    display: none;
  }
}

.search-icon {
  width: 15px;
  height: 15px;
  cursor: pointer;

  &:hover {
    opacity: .5;
  }

  a {
    display: flex;
  }

   svg {
     object-fit: contain;
     width: 100%;
     max-width: 100%;
     fill: $light;
   }
}

/*
Lang
*/
.lang {
  display: flex;

  @media (max-width: 768px) {
    display: none;
  }

  a {
    font-size: 0.875rem;
    color: $light;
    text-decoration: none;
    opacity: .3;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }

    &.active,
    &:hover {
      opacity: 1;
    }
  }
}

/*
Account Button
*/

.account-button {
  cursor: pointer;

  &:hover {
    opacity: .5;

    svg circle,
    svg path {
      fill: $black;
    }
  }

  a {
    display: flex;
  }

  svg {
    max-width: 10px;
    width: 100%;
    stroke-width: 1px;
    stroke: $light
  }
}

/*
Primary Header
*/

.primary-header {
  position: fixed;
  left: 0;
  top: 0;
  border-bottom: 1px solid $lightAccentColor;
  background: #f4f2f0;
  width: 100%;
  height: 50px;
  z-index: 50;

  @media (max-width: 1400px) {
    height: 45px;
  }

  @media (max-width: 600px) {
    height: 45px;
  }

  &.two-columns {
    @media (max-width: 1280px) {
      grid-template-columns: 1fr;
    }
  }

  h4 {
    margin: 0;
    line-height: 1em;
    font-size: 1.25rem;

    @media (max-width: 1400px) {
      font-size: 1.3rem;
    }

    @media (max-width: 1280px) {
      font-size: 1.5rem;
    }

    @media (max-width: 768px) {
      width: calc(100svw - 100px);
    }

    @media (max-width: 600px) {
      font-size: 1.25rem;
    }
  }
}

body.search .primary-header {
  border-bottom: 1px solid $darkAccentColor;
}

.header-left-panel {
  background: #E8E8E8;
  padding: 17px 20px;
  height: 49px;

  @media (max-width: 1400px) {
    height: 44px;
  }

  @media (max-width: 768px) {
    height: auto;
    padding: 20px 20px 15px;
  }

  @media (max-width: 600px) {
    padding: 18px 20px 15px;
  }

  a{
    display: flex;
    flex-direction: row;
    color: $black;
    text-decoration: none;

    &:hover {
      .subtitle {
        opacity: .25;
      }
    }
  }

  .subtitle {
    padding-left: 10px;

    @media (max-width: 768px) {
      display: none;
    }
  }
}

.sessions-title {
  opacity: .25;
}

.header-right-panel {
  padding: 17px 20px;
  width: 60%;
  opacity: .25;

  @media (max-width: 1280px) {
    display: none;
  }
}

/*
Nav
*/

nav {
  font-size: 1.5rem;
  padding: 80px;

  @media (max-width: 2000px) {
    font-size: 2.5rem;
  }

  @media (max-width: 1280px) {
    font-size: 2rem;
    padding: 20px 40px;
  }

  @media (max-width: 600px) {
    padding: 10px 20px;
  }

  a {
    display: block;
    margin: 40px 0;
    color: $light;
    text-decoration: none;

    @media (max-width: 1280px) {
      margin: 20px 0;
    }

    @media (max-width: 600px) {
      margin: 10px 0;
    }

    &:hover {
      opacity: .5;
    }

    &.gap-40 {
      @media (max-width: 1024px) {
        grid-gap: 40px;
      }

      @media (max-width: 600px) {
        grid-gap: 0;
      }
    }
  }
}

/*
Sticky Nav
*/
.sticky-nav {
  text-align: center;
  padding: 10px;
  background: #F4F2F0;
  position: sticky;
  top: 49px;
  z-index: 20;
  border-bottom: 1px solid $lightAccentColor;
  border-top: 1px solid $lightAccentColor;

  @media (max-width: 1400px) {
    top: 44px;
  }

  @media (max-width: 768px) {
    position: relative;
    top: 0;
    margin: 40px 0;
    padding: 40px 20px;
  }

  a {
    text-align: center;
    padding: 0 7px;
    border-right: 1px solid $lightAccentColor;
    color: $black;

    @media (max-width: 768px) {
      border: 0;
      display: block;
      padding: 2px;
      font-size: 1.5rem;
      text-align: left;
    }

    &:hover {
      opacity: .4;
    }

    &:last-child {
      padding-right: 10px;
      border-right: 0;
    }
  }
}
