.button {
  background: transparent;
  color: $light;
  border:1px solid $light;
  border-radius: 20px;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  padding: 5px 10px;
  cursor: pointer;
  text-transform: uppercase;
  font-family: $fontHelvetica;
  font-size: 0.75rem;
  line-height: 1.25;
  margin-right: 10px;

  &:hover {
    background:  $light;
    color: $black;
    border:1px solid $light;
  }

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }

  &.form-button {
    margin-top: 20px;
  }
}

a.large-button {
  padding: 1.5rem 2rem 1rem;
  font-size: 1.875rem;
  border-radius: 3rem;
  border: 1.5px solid;
}

button {
  background: transparent;
  color: $light;
  border: 1px solid $light;
  border-radius: 20px;
  display: block;
  margin-top: 20px;
  padding: 5px 10px 2px;
  cursor: pointer;
  text-transform: uppercase;
  font-family: $fontHelvetica;
  font-size: 0.75rem;
  line-height: 1.25;

  &:hover {
    background: $black;
    color: $light;

    a {
      color: $light;
    }
  }

  a {
    color: $black;
  }
}


.dark-button {
  background: transparent;
  color: $black;
  border:1px solid $black;
  border-radius: 20px;
  display: block;
  margin-top: 20px;
  padding: 5px 10px 2px;
  cursor: pointer;
  text-transform: uppercase;
  font-family: $fontHelvetica;
  width: fit-content;
  margin-right: 5px;

  &.sm {
    font-size: 0.75rem;
    line-height: 1.25;
  }

  &.inline {
    display: inline-flex;
  }

  &:hover {
    color: $black;
    background: $light;
    cursor: pointer;
    text-decoration: none;

    a{
      color: $black;
    }
  }

   a {
     text-decoration: none;
     color: $black;
   }
}

.tag {
  padding: 5px 10px 1px 10px;
  border: 1px solid;
  margin-top: 10px;
  display: inline-flex;
  line-height: 1em;
  font-size: 12px;
  border-radius: 10px 10px 10px 10px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background: white;
    color: black;
  }
}

.small-button {
  font-size: 0.75rem;
  line-height: 1.25;
}

/*
Buttons
*/
/*Button as a class*/
#block-3 .button {
  margin-top: 20px;
}
#block-3 a.button:hover {
  opacity: 1;
}

.forum a.large-button {
  margin-top: -30px;
}
