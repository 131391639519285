// --------------------------------------------------
// Base
// --------------------------------------------------

* {
  box-sizing: border-box;
}

body,
html {
  font-size: 16px;
  line-height: 1.25;

  @media (max-width: 1400px) {
    font-size: 13px;
  }

  @media (max-width: 1200px) {
    font-size: 12px;
  }
}

html {
  margin: 0;
  padding: 0;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-smooth: always;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: sans-serif;
  font-kerning: normal;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  font-family: $fontHelvetica;
  @extend .regularText;
  color: $dark;
  background: $mainColor;
}

.fonts-loaded body {
  font-family: $fontHelvetica;

  .serif {
    font-family: $fontSuisse;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: Helvetica, Arial, sans-serif;
}

.fonts-loaded h1,
.fonts-loaded h2,
.fonts-loaded h3,
.fonts-loaded h4,
.fonts-loaded h5,
.fonts-loaded h6, {
  font-family: $fontHelvetica;
}

.fonts-loaded .serif {
  h1, h2, h3,
  h4, h5, h6 {
    font-family: $fontSuisse;
  }
}

p {
  font-size: 1rem;
  line-height: 1.25;
  margin-bottom: 1rem;
}

a {
  color: inherit;
  text-decoration: none;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #C2C7D0;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #C2C7D0;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #C2C7D0;
}
:-moz-placeholder { /* Firefox 18- */
  color: #C2C7D0;
}

.hide-for-screen {
  font-size: 0;
}

/* -- For screen readers */
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    //overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

/* -- A subtle focus style for keyboard-input elements */
.text-input:focus {
    outline: 1px solid #5db7a5; /* Adjust to suit your tastes */
}

/* -- No outline for non-keyboard-inputs elements */
button:focus,
select:focus {
    outline: none;
}

html.user-is-tabbing *:focus {
    outline: 2px solid var(--outlineColor) !important; /* for non-webkit browsers */
    outline: 5px auto -webkit-focus-ring-color !important;
}

/* -- Fontello */

.fonts-loaded [class^="icon-"]:before {
    font-family: "fontello";
}

.fonts-loaded [class*=" icon-"]:before {
    font-family: "fontello";
}

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

::-moz-selection { background: $colorAccent1; color: #fff; }
::selection { background: $colorAccent1; color: #fff; }

[v-cloak] {
  display: none;
}

.site-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


main {
  padding: 45px 0 20px 0;

  @media (max-width: 768px) {
    padding: 50px 0;
  }

  @media (max-width: 600px) {
    padding: 49px 0;
  }
}

.content-wrapper {
  width: 100%;
  padding: 40px;
}

.custom-git-branch {
  position: fixed;
  display: flex;
  align-items: center;
  left: 20px;
  bottom: 20px;
  padding: 5px 10px;
  border-radius: 6px;
  background-color: $colorAccent1;
  color: #fff;
  z-index: 999999;
  pointer-events: none;

  & > svg {
    width: auto;
    margin-right: 5px;
  }
}

/*
Dark Mode
*/
.site-container.dark {
  background: $black;
  color: $light;

  a {
    color: $light;

    &:hover {
      opacity: .5;
    }
  }

  .divider {
    span {
      background: $darkAccentColor;
    }
  }

  .primary-header {
    background: $black;
    border-bottom: 1px solid $darkAccentColor;
  }

  .header-left-panel {
    background: $black;

    a {
      color: $light;
    }
  }

  .account-button {
    svg path,
    svg circle {
      fill: $light;
    }
  }
}

.author {
  display: block;
}
