/*
Shuffle Animation
*/

@keyframes shuffle {
  0% {
  }
  100% {
  }
}
.shuffle {
  animation: shuffle 10s ease-in-out;
  cursor: pointer;
}
/*
Page Transition
*/

#fader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  pointer-events: none;
  background: #F4F2F0;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;

  &.fade-out {
    opacity: 0;
    animation-name: fade-out;
  }

  &.fade-in {
    opacity: 1;
    animation-name: fade-in;
  }
}
.dark #fader{
  background: $black;
}

@keyframes fade-out {
  from { opacity: 1 }
  to { opacity: 0 }
}

@keyframes fade-in {
  from { opacity: 0 }
  to { opacity: 1 }
}
