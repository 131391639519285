/*
Profile
*/

.profile {
  width: 100%;
  border-bottom: 1px solid $lightAccentColor;
  place-items: center;

  @media (max-width: 768px) {
    place-items: flex-start;
    display: flex;
    flex-direction: row-reverse;
    justify-content: left;
  }

  .full-height {
    @media (max-width: 768px) {
      padding: 60px 20px;
    }
  }

  h1{
    padding-bottom: 20px;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  .image {
    width: 200px;
    max-width: 100%;

    img{
      width: 100%;
      object-fit: cover;
      object-position: center;
      aspect-ratio: 1/ 1;
      border: 1px solid $lightAccentColor;
      margin: 20px 0;
      padding: 3px;

      @media (max-width: 768px) {
        width: 80px;
        margin-bottom:20px;
        padding: 0;
      }
    }
  }

  .profile-picture {
    border-right: 1px solid $lightAccentColor;
    width: calc(50vw - 10px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 768px) {
      border-right: 0;
      align-items: flex-start;
      justify-content: flex-start;
      width: 120px;
    }
  }
}


/*
Related Page
*/

.related-entry {
  padding-top: 20px;
  position: relative;
  z-index: 10;
  border-bottom: 1px solid $lightAccentColor;

  &:hover {
    color: $light;
    background: $black;

    .post-info {
      color: $light;
      background: $black;
    }

    a {
      color: $light;
    }
  }

  .author {
    padding-top: 20px;
  }

  .entry-container {
    padding-bottom: 40px;
  }

  a {
    color: $black;

    &:hover {
      opacity: .5;
    }
  }
}

.contributors {
  a {
    display: block;
  }
}
