/* Grids */
.grid {
  display: grid;
}
.two-columns {
  grid-template-columns: 1fr 1fr;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
}
.three-columns {
  grid-template-columns: 1fr 1fr 1fr;
}
.four-columns {
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media (max-width: 1280px) {
    .four-columns {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
}
.five-columns {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.gap-20 {
  grid-gap: 20px;
}
.gap-40 {
  grid-gap: 40px;
}
.gap-60 {
  grid-gap: 60px;
}
