// --------------------------------------------------
// Import Fonts
// --------------------------------------------------

@font-face {
  font-family: 'Nexa';
  src: url('/fonts/nexaregular-webfont.woff2') format('woff2'),
  url('/fonts/nexaregular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src: url('/fonts/nexabold-webfont.woff2') format('woff2'),
  url('/fonts/nexabold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src: url('/fonts/nexaheavy-webfont.woff2') format('woff2'),
  url('/fonts/nexaheavy-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/HelveticaNeue.woff2') format('woff2'),
  url('/fonts/HelveticaNeue.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Works';
  src: url('/fonts/SuisseWorks-Regular.woff2') format('woff2'),
  url('/fonts/SuisseWorks-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Works';
  src: url('/fonts/SuisseWorks-RegularItalic.woff2') format('woff2'),
  url('/fonts/SuisseWorks-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
