/*
Mobile
*/
.mobile-menu {
  display: none;

  @media (max-width: 600px) {
    display: block;
  }
}

/*Panel*/

.panel-wrapper {
  padding: 20px;

  @media (max-width: 1280px) {
    padding: 20px 20px 10px;
  }

  @media (max-width: 600px) {
    padding: 17px 20px 10px;
  }

  h4 {
    @media (max-width: 1280px) {
      margin-bottom: 0;
    }
  }
}

.menu-info-panel {
  border-right: 1px solid $darkAccentColor;
  background-color: $black;

  .subtext {
    h5 {
      @media (max-width: 1280px) {
        font-size: 1rem;
      }
    }
  }
}

.menu-nav-panel {
  border-right: 1px solid $darkAccentColor;
  background-color: $black;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 768px) {
    padding-bottom: 120px;
  }

  .two-columns{
    @media (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 600px) {
      display: block;
    }
  }

  a {
    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
}

.left-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1280px) {
    border-bottom: 1px solid $darkAccentColor;
  }
}

.phi-sessions{
  max-width: 50%;
  display: block;
  padding-bottom: 40px;
  color: $black;
}

.menu-description {
  font-family: $fontSuisse;
  margin: 80px 0 0 auto;
  padding-right: 60px;

  @media (max-width: 1280px) {
    padding-right: 0;
    margin: 20px 0 20px auto;
  }

  h3 {
    @media (max-width: 1280px) {
      font-size: 1.25rem;
    }
  }
}

/* Menu */

.menu {
  position: fixed;
  height: calc(100vh - 90px);
  width: 100%;
  top: 0;
  left: 0;
  background-color: $black;
  color: $light;
  display: block;
  pointer-events: none;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  opacity: 0;
  transition: opacity .15s ease-in-out;
  z-index: 100;

  @media (max-width: 768px) {
    height: calc(100svh - 30px);
  }

  a {
    color: $light;

    &:hover {
      opacity: .25;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .menu-wrapper {
    height: calc(100svh - 90px);

    &.two-columns {
      @media (max-width: 1280px) {
        display: block;
      }
    }

    .menu-logo {
      max-width: 500px;
      padding-bottom: 45px;

      @media (max-width: 768px) {
        max-width: 80%;
        padding-bottom:20px;
      }

      @media (max-width: 600px) {
        padding-bottom: 10px;
      }

      h1 {
        @media (max-width: 1280px) {
          font-size: 1.5rem;
        }

        @media (max-width: 768px) {
          margin-bottom: 5px;
        }

        @media (max-width: 600px) {
          font-size: 1.25rem;
          margin-bottom: 0;
        }
      }
    }
  }
}

.menu-button {
  position: fixed;
  top: 0;
  right: 0;
  margin: 14px;
  z-index: 1000;
  cursor: pointer;
  mix-blend-mode: exclusion;

  @media (max-width: 1400px) {
    margin: 11px 15px;
  }

  @media (max-width: 1280px) {
    margin: 16px;
  }

  &:hover {
    opacity: .5;
  }

  span {
    display: block;
    height: 1px;
    background: $light;
    width: 60px;
    margin: 5px 0;

    @media (max-width: 1280px) {
      width: 60px;
    }

    @media (max-width: 768px) {
      width: 40px;
    }

    &:first-child {
      @media (max-width: 1280px) {
        margin-top: 2px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.active-menu {
  .menu {
    opacity: 1;
    pointer-events: auto;
  }

  .menu-button {
    span {
      opacity: 0;

      &.close-menu {
        opacity: 1;
      }
    }
  }

  .icons {
    @media (max-width: 600px) {
      display: flex;
    }
  }
}

/* Menu Login */
.menu-account {
  display: block;
  border-top: 1px solid $darkAccentColor;
  width: 100%;

  @media (max-width: 600px) {
    display: none;
    height: auto;
  }

  h3 {
    @media (max-width: 1280px) {
      cursor: pointer;
    }

    @media (max-width: 768px) {
      font-size: 0.875rem;
    }

    &:hover {
      @media (max-width: 1280px) {
        opacity: .5;
      }
    }
  }

  .two-columns {
    @media (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  form {
    @media (max-width: 768px) {
      font-size: 0.875rem;
    }
  }

  input{
    &.enter {
      background: transparent;
      color: $black;
      border:1px solid $black;
      border-radius: 15px;
      display: block;
      margin: 0;
      margin-top: 20px;
      padding: 5px 12px;
      cursor: pointer;
      text-transform: uppercase;

      &:hover {
        background: $black;
        color: $light;
      }
    }
  }
}

.menu-login {
  border-right: 1px solid $darkAccentColor;

  @media (max-width: 768px) {
    padding: 20px;
  }

  @media (max-width: 600px) {
    width: 100%;
    border-right: 0;
    border-bottom: 1px solid $darkAccentColor;
  }

  input {
    background: transparent;
    color: $light;
    border: 0;
    border-bottom: 1px solid $lightAccentColor;
    margin-left: 10px;
  }

  label {
    line-height: 1;
    margin: 0;
    padding: 0 10px 0 0;
    display: flex;
    height: 10px;
    align-self: end;
  }

  form {
    @media (max-width: 600px) {
      padding: 0;
    }

    .form-fields {
      @media (max-width: 600px) {
        grid-template-columns: 1fr;
      }
    }

    input {
      @media (max-width: 600px) {
        width: 100%;
      }

      button {
        @media (max-width: 600px) {
          width: auto;
        }
      }
    }
  }
}

.login-widget {
  @media (max-width: 600px) {
    display: none;
  }
}

.login-header {
  padding-bottom: 20px;
}

.registration-header {
  padding-bottom: 20px;
}

.menu-register {
  display: block;

  @media (max-width: 768px) {
    padding: 20px;
  }

  button {
    display: block;
  }
}

/* Menu Search Bar */

.search-bar {
  width: 100%;
  height: 60px;
  background-color: $black;
  border-top: 1px solid $darkAccentColor;
  border-bottom: 1px solid $darkAccentColor;
  position: fixed;
  bottom: 30px;
  z-index: 100;

  @media (max-width: 768px) {
    height: 40px;
  }
}

.search-form{
  height: 60px;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    height: 38px;
  }
}

.input-search {
  background: transparent;
  color: $light;
  width: 80%;
  border: none;
  padding-left: 20px;
  font-size: 1.5rem;

  &:focus {
    outline: solid 0;
  }

}

.search-submit {
  width: 20%;
  border: none;
  background: transparent;
  color: $light;
  border-left: 1px solid $darkAccentColor;
  cursor: pointer;

  &:hover {
    background: $light;
    color: $black;
  }
}


/*
Login
*/

.login-widget {
  position: fixed;
  right: 20px;
  top: 70px;

  .dark-button {
    margin-top: 0;
    background: #f4f2f0;
  }
}
