.footer  {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 30px;
  background: $black;
  color: $light;
  display: inline-flex;
  flex-direction: revert;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  left: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 1;
  z-index: 10;
  border-top: 1px solid $darkAccentColor;

  @media (max-width: 768px) {
    font-size: 10px;
  }

  a {
    color: $light;
    text-decoration: none;
  }

  .footer-item {
    display: flex;
    flex-direction: row;
    width: 180px;
    justify-content: flex-start;
    align-content: center;
    height: 10px;
    padding: 0 10px;

    @media (max-width: 768px) {
      padding: 0;
      width: auto;
    }

    img {
      max-width: 15px;
      margin-right: 5px;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  div {
    cursor: pointer;

    &:hover {
      opacity: .5;
    }
  }
}
