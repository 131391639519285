// Titles
h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 2.5rem;
  line-height: 1.2;
  margin: 0;

  &.bold, strong {
    font-weight: 600;
  }
}


h2 {
  font-size: 2rem;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  font-weight: normal;

  &.bold, strong {
    font-weight: 600;
  }
}

h3 {
  font-size: 1.75rem;
  line-height: 1.2;
  margin-bottom: 1rem;
  font-weight: normal;

  &.bold, strong {
    font-weight: 600;
  }

}

h4 {
  font-size: 1.5rem;
  line-height: 1.2;
  margin-bottom: 1rem;
  font-weight: normal;

  &.bold {
    font-weight: 600;
  }
}

h5 {
  font-size: 1.25rem;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  font-weight: normal;

  &.bold {
    font-weight: 600;
  }
}

// Texts
.titleBold {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
  margin: 0;

  @media (max-width: 767px) {
    font-size: 16px;
  }
}

.regularText {
  font-size: 1rem;
  line-height: 1.25;
  margin-bottom: 1rem;

  &.bold {
    font-weight: 600;
  }
}

/*.subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0;

  &.bold {
    font-weight: 600;
  }
}*/

.caption {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 115%;
  margin: 0;

  &.bold {
    font-weight: 600;
  }
}

small {
  font-size: 0.875rem;
}

b {
  font-weight: 600;
}

i {
  font-family: $fontSuisse;
  font-style: italic;
}

.serif {
  font-family: $fontSuisse;
}

sup {
  top: 0.5em;
  font-size: 70%;
  vertical-align: super;

  a:visited{
    color:#0e0eee;
  }
}

hr {
  height: 1px;
  background:  $lightAccentColor;
  width: 100%;
  border: none;
  margin: 0;
}
a {
  cursor: pointer;
  text-decoration: none;
  color: $black;

  &.active {
    opacity: .25;

    &:hover {
      opacity: 1;
    }
  }

  &.dark-button {
    text-decoration: none !important;

    &:hover {
      opacity: 1 !important;
    }
  }
}
