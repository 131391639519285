.homepage {
  main {
    height: 10000px;
    margin: 0;
    padding: 0;
    top: 0;
    left:0;

    .container {
      margin-top: 0;
    }
  }

  a {
    &.content-box {
      &.main-link {
        display: flex;

        a {
          color: $dark;

          &:hover {
            opacity: .5;
            text-decoration: none;
          }
        }
      }

      &:hover {
        color: $light;
        animation: all 1s;
        text-decoration: none;
      }
    }

    &.main-link {
      &:hover {
        background: $black;
        color: $light;

        h2 {
          color: $light;
        }
      }

      sup {
        margin-left: 5px;
      }
    }
  }

  img {

    &.image-content {
      max-width: 100%;
    }

    &.image-thumbnail {
      width: 100%;
      position: absolute;
      height: 25vh;
      object-fit: cover;
      object-position: top;
      top: 0;
      left: 0;
      z-index: 0;
      opacity: 0;
      pointer-events: none;
      transition: all .1s linear;
      filter: blur(100px);
      -webkit-filter: blur(100px);
    }
  }

  .date {
    padding-top: 10px;
    display: block;
  }

  .list-container {

    &:hover {
      .more {
        opacity: 1;
      }
    }

    .more {
      opacity: 0;
      position: absolute;
      top: 20px;
      right: 20px;
      color: $black;

      &:before {
        content: '+';
      }
    }
  }

  .primary-header {
     display: none;
   }

  .container {
    position: relative;
  }

  .landing-logo {
    h1 {
      font-size: 2.875rem;

      @media (max-width: 1440px) {
        font-size: 2.875rem;
      }

      @media (max-width: 1280px) {
        font-size: 2.5rem;
        width: 90%;
      }

      @media (max-width: 768px) {
        font-size: 2.25rem;
      }

      @media (max-width: 600px) {
        font-size: 2.25rem;
      }
    }

    .content-box {

      a {
        &:hover {
          text-decoration: none;
          opacity: .25;
        }
      }
    }
  }

  .landing {
    &.sessions {
      h1 {
        font-size: 13.5rem;
        letter-spacing: -16px;
        opacity: .1;
        line-height: 1;
        margin-top: 0;
      }

      svg {
        opacity: .05;

        @media (max-width: 1440px) {
          object-fit: contain;
          width: 600px;
          height: auto;
        }
      }
    }
  }

  .content-scroll {
    overflow: hidden;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .edition-subject {
    opacity: .25;
  }

  .thumbnail-content {
    z-index: 1;
    position: relative;
    height: 100%;
    display: block;

    a {
      color: $black;
    }

    h4 {
      margin: 0 0 10px;
    }
  }

  .content-box {
    &:hover {
      .thumbnail-content{
        color: $light;
        animation: all 1s;
        text-decoration: none;

        + img.image-thumbnail {
          opacity: 1;
          transition:  all .5s;
        }
      }
    }
  }

  .content-container {
    float: left;
    height: 100%;
    position: relative;
    display: block;
    overflow: hidden;

    > .content-box {
      display: block;
      height: 100%;
      width: 500px;
      color: #f2f2f2;
    }
  }

  #block-2,
  #block-3,
  #block-4 {
    .content-box {
      width: 400px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom;
      color: $black;
      padding: 20px;
      cursor: pointer;

      @media (max-width: 1440px) {
        width: 300px;
      }

      @media (max-width: 1280px) {
        font-size: 1rem;
      }

      &:hover {
        color: $light;
      }

      h4 {
        margin-bottom: 10px;
      }
    }

    .content-container {
      &:first-child {
        .content-box {
          width: 300px;

          @media (max-width: 1440px) {
            width: 200px;
          }

          h2 {
            @media (max-width: 768px) {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }

  #block-1 {
    left: 0;
    height: calc(25% - 30px);
    transition: height 0.25s ease-in-out;
    z-index:99;
    border-bottom: 1px solid #aaa;

    @media (max-width: 1440px) {
      height: 23svh;
    }

    .content-scroll {
      a {
        color: $black;
      }
    }

    .content-box {
      width: 1000px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom;
      color: $black;
      padding: 20px;
      font-size: 60px;
      cursor: pointer;
      transition: background-color 0.25s ease-in-out;

      &:hover {
        .content {
          opacity: 1;
          height: auto;
          pointer-events: auto;
          position:relative;
        }
      }

      .content {
        opacity: 0;
        height: 0;
        pointer-events: none;
        display: block;
        position: absolute;
      }
    }

    .content-container {
      &.landing-logo {
        .content-box {
          width: 695px;

          @media (max-width: 1440px) {
            width: 600px;
          }

          @media (max-width: 1280px) {
            width: 430px;
          }

          @media (max-width: 600px) {
            width: 100vw;
          }
        }
      }

      .content-box {
        @media (max-width: 768px) {
          width: auto;
        }
      }
    }
  }

  #block-2 {
    height: 25%;
    top: calc(25% - 30px);
    border-bottom: 1px solid #aaa;

    @media (max-width: 1440px) {
      height: 23svh;
      top: 23svh;
    }

    img.image-thumbnail {
      height: 25vh;
    }

    .content-box {
      @media (max-width: 600px) {
        width: calc(100vw - 140px);
      }
    }
  }

  #block-3 {
    height: 25%;
    top: calc(50% - 30px);
    z-index: 10;
    transition: height 0.25s ease-in-out;

    @media (max-width: 1440px) {
      height: 23svh;
      top: 46svh;
    }

    .content-box-content {
      opacity: 0;
      font-size: 0.875rem;

      p {
        display: inline;
      }
    }

    .content-box {
      &:hover {
        background-color: $black;
        color: $light;
        transition: 0.25s ease background;

         .content-box-content {
          opacity: 1;
          color: $light;
        }
      }
    }

    a {
      &:hover {
        text-decoration: none;
        opacity: .5;
      }

      &.button {
        &:hover {
          opacity: 1;
        }
      }

      &.main-link {
        &:hover {
          text-decoration: none;
          opacity: 1;
        }
      }
    }

    .content-scroll {
      overflow: auto;
    }

    .content-box {
      h4 {
        a {
          color: $black;
        }
      }

      &:hover {
        h4 {
          a {
            color: $light;
          }
        }
      }
    }
  }

  #block-4 {
    height: 25%;
    top: calc(75% - 30px);
    border-top: 1px solid #aaa;

    @media (max-width: 1440px) {
      height: 27svh;
      top: 68.5svh;
    }

    .content-box {
      &:hover {
        color: $black;
      }
    }

    .tag {
      padding: 0 5px 0 0;
      border: 0 solid;
      margin-top: 0;
      display: inline-flex;
      line-height: 1em;
      font-size: 8px;
      border-radius: 10px 10px 10px 10px;
      text-transform: uppercase;

      &:hover {
        background: transparent;
        color: white;
        opacity: .5;
      }
    }

    .form-fields {
      input {
        max-width: 100%;
        background: transparent;
        border: 0;
        border-bottom: 1px $black solid;
        justify-self: flex-end;
        width: 100%;
      }

      label{
        display: flex;
        align-items: end;
      }
    }

    img.image-thumbnail {
      -webkit-filter: blur(0px);
    }
  }
}

/* Vertical Scroll Blocks */

.block {
  width: 10000px;
  position: fixed;
  height: 5000px;
  left: 0;
  overflow: hidden;

  > .container {
    float: left;
    display: block;
    position: relative;
    height: 100%;
  }
}

body.block-1-open {
  overflow: hidden;

  #block-1 {
    @media (max-width: 1280px) {
      height: 100%;
      transition: 0.25s ease-in-out;
    }

    .content-scroll {
      overflow: hidden;
    }
  }
}

body.block-3-open #block-3 {
  height: 44.55%;
  transition: 0.25s ease-in-out;
}

body.block-2-open {

  #block-1,
  #block-3,
  #block-4 {
    pointer-events: none;
    opacity: .2;
    transition: opacity .5s;
  }

  #block-2 {
    .list-container .more::before {
      content: '-';
    }
  }
}

body.block-3-open {
  overflow: hidden;
}
