// --------------------------------------------------
// Variables
// --------------------------------------------------

// Import Web Fonts
// @import url(...);

// Fonts
$fontNexa: 'Nexa', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
$fontHelvetica: 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
$fontSuisse: 'Suisse Works', 'sans-serif';

// Accents Colors
$colorAccent1: #00BCE7;
$colorAccent2: #004053;
$colorAccent3: #FFCB07;
$mainColor: #f4f2f0;
$lightAccentColor: #d2d2d2;
$darkAccentColor: #262626;

$lightText: #73879A;

// Texts
$dark: #0D313F;
$grey: #F4F8FF;
$light: #FFFFFF;
$black: #000000;

$colorSuccess: #00966D;
$colorError: #FF564A;
