/*

*/
.full-height {
    height: calc(100vh - 80px);
}
.half-height {
    height: calc(50vh - 80px);
}

/*
Flex Grid
*/

.flex-grid {
    display: flex;
    flex-direction: row;
}
.flex-masonry {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}
.flex {
    display: flex;
    width: 100%;
}
.flex.block div{
    padding-right: 20px;
}
.flex.center {
    justify-content: center;
    align-items: center;
}
.flex.space-in-between {
    justify-content: space-between;
}
.flex.right {
    justify-content: flex-end;
}
.flex.bottom {
    align-self: flex-end;
}
.flex.bottom {
    align-items: flex-end;
}
.flex.column{
    flex-direction: column;
}

/*
Padding
*/

.padding-20 {
    padding: 20px;
}
.padding-40 {
    padding: 40px;
}
.padding-60 {
    padding: 60px;
}
.padding-80 {
    padding: 80px;
}
.top-20 {
    padding-top: 20px;
}

.border-bottom {
    border-bottom: 1px solid $lightAccentColor;
}
/*
List
*/

.list-container ul {
    opacity: 0;
    pointer-events: none;
    display: block;
    list-style-type: decimal;
    list-style-position: inside;
}
.list-container:hover ul {
    opacity: 1;
    pointer-events: auto;
}
.list-container a{
    color: $light;
}
.list-title {
    top:0;
}
.block li {
    list-style-position: outside;
    margin: 0px 20px;
    padding: 2px;
    line-height: 1.25rem;
}
.bullet-points ul li {
    margin-bottom: 20px;
    list-style: disc;
}

/*
Default Page
*/

.default-page .main-panel {
    padding: 20px 30px;
}
.default-page .content-wrapper {
    padding: 20px;
}
.default-page main a{
    text-decoration: underline;
}
.default-page main a:hover{
    opacity: .5;
}
.default-page ul {
    list-style: circle;
    list-style-position: outside;
    padding:20px  60px 20px;
}
.default-page ul li{
    padding: 0px 0px 10px;
    line-height: 1.25;
}
.default-page hr{
    margin: 20px 0px;
}
.default-page h5{
    margin-bottom: 1rem;
}
.default-page small{
    margin-bottom: .5rem;
    display: inline-flex;
}

/*
Images
*/

figure {
    padding: 40px;

}
figure.large {
    max-width: 100%;
    padding: 20px 0px;
    margin: 0 auto;
}
figure.med {
    max-width: 50%;
    padding: 20px 0px;
    margin: 0 auto;
}
figure.sm {
    max-width: 25%;
    padding: 20px 0px;
    margin: 0 auto;
}
figcaption {
    padding-top: 10px;
    opacity: .5;
    font-size: 12px;
}
img {
    max-width: 100%;
    width: 100%;
}

/*
Cateories
*/

.cat-flex {
    display: flex;
    flex-wrap: wrap;
}
.cat-flex button{
    margin-top: 10px;
}
.cat-flex button{
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 0px;
}
.cat-container a{
    text-decoration: none;
    color:$black;
    opacity: 1;
    font-size: 0.875rem;
}
.cat-container a:hover{
    opacity: 1;
}

/*
Banner
*/

.banner {
    border-bottom: 1px solid $lightAccentColor;
}
.banner h2,.banner h4 {
    margin: 0;
    line-height: 1;
    vertical-align: baseline;
}

/*
Pop Up
*/
.popup-banner {
    position: fixed;
    width: 400px;
    max-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
}
.popup-banner-content {
    padding: 40px;
    text-align: center;
    background: $black;
    color: $black;
    text-align: left;
}
.popup-banner-content p {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
}
.popup-banner.show {
    opacity: 1;
    visibility: visible;
}

.popup-banner a {
    margin-top: 30px;
}
.popup-banner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.7);
    z-index: 9998;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
}

.popup-banner-overlay.show {
    opacity: 1;
    visibility: visible;
}
.close-popup {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}
/*
Header
*/

header sup {
    font-size: 40%;
    margin-left: 5px;
}


/*
Tooltip
*/

.tooltip {
    position: relative;
    display: inline-block;
    z-index: 2;
}
.tooltip .tooltip-container {
    visibility: hidden;
    width: 200px;
    background-color: $black;
    color: $black;
    text-align: left;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 4px;
    right: -10px;
    margin: 10px;
    opacity: 0;
    transition: opacity 0.3s;
    box-sizing: border-box;
    border: 1px solid;
}
.tooltip-container a{
    color: $black;
    visibility: hidden;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 10px;
    cursor: pointer;
}
.tooltip:hover .tooltip-container {
    visibility: visible;
    opacity: 1;
}
.tooltip:hover .tooltip-container a{
    visibility: visible;
}

/*
Filter
*/

.filter {
    padding: 10px 20px;
    border-top: 1px solid $lightAccentColor;
    border-bottom: 1px solid $lightAccentColor;
    /*	display: flex;*/
    /*	flex-direction: row;*/
    width: 100%;
    align-items: first baseline;
    justify-content: space-between;
}
.filter h5{
    margin: 0;
    padding: 0;
    font-size: 1rem;
}
.dropdown {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.dropdown button {
    margin: 0;
    font-size: 1rem
}
.dropdown select, .dropdown label {
    margin-right: 20px;
    font-size: .875rem;
    font-family: 'Helvetica Neue';
    -webkit-appearance: none;
}
.dropdown select {
    opacity: .5;
}
.filter select{
    background-color: transparent;
    border: none;
}

.filter option {
    background-color: transparent;
    color: inherit;
}
.filter:checked option {
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: none;
    outline: none;
}
.filter:focus {
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: none;
}

/*
List
*/

ul li ul {
    padding-top: 0px;
    margin-left: 30px;
}
ul li {
    padding: 10px 0px 0px;
}
.list {
    width: 100%;
}
.list ul {
    padding: 10px 0px;

}
.list li{
    padding: 10px 10px;
    margin:0px;
    border-bottom: 1px solid $lightAccentColor;
    font-size: 1rem;
    width: 100%;
}

.list li a:hover {
    opacity: .25 !important;
}
.list ul li a{
    display: flex;
    justify-content: space-between;

}
.list a:last-child {
    border-bottom: 0px;
}
.list li:last-child {
    border-bottom: 0px;
}
.list a {
    border-bottom: 1px solid $lightAccentColor;
}

/*
Checkbox
*/
.checkbox-container {
    display: flex;
    align-items: center;
}
.checkbox-container input[type="checkbox"] {
    border: 1px solid $black;
    margin-bottom: 6px;
    margin-right: 5px;
    background: transparent;
}

/*
Default Header
*/
.default-header {
    min-height: 300px;
    text-align: center;
    /*	background: $black;*/
    color: $black;
    display: flex;
    position: relative;
    align-items: center;
}

/*
Divider
*/

.divider {
    width: 100%;
    display: flex;
    position: fixed;
    pointer-events: none;
    z-index: 1;
}
.divider span {
    width: 1px;
    height: calc(100vh - 30px);
    background-color: $lightAccentColor;
    margin: 0 auto;

}
.divider.absolute {
    position: absolute;
    left: 0;
}
.divider.absolute span{
    height: 100vh;

}
/*
Anchor Link
*/

.anchor-link {
    padding-top: 80px;
    margin-top: -80px;
}

/*
Landing page
*/
.landing-page .primary-header{
    display: none;
}
.landing-page .landing.sessions svg {
    opacity: 1;
}
.landing-page a {
    color: $black;
}
.landing-page main {
    padding: 0;
}
.landing-page .sessions svg path, .about .sessions svg path  {
    fill: transparent;
    stroke: $black;
    stroke-width: 1.5px;
}
.landing-page .content-wrapper {
    padding: 20px;
}
.subtext {
    max-width: 550px;
    opacity: 1;
}
.landing-page .container {
    padding: 0;
    margin: 0;
}
.landing-page #block-1 .content-box {
    color: $black;
}
.landing-page #block-2 .content-container {
    width: 100vw;
}
.landing-page #block-4 .content-container {
    width: 600px;
    padding: 30px 20px;
}
.landing-page #block-3 .content-container{
    width: auto;
    padding: 30px 20px;
}
.landing-page #block-3 a.active {
    padding: 10px 15px 2px;
    border: 1px solid $black;
    border-radius: 50px;
}
.landing-page #block-4 a.enter {
    padding: 10px 15px 2px;
    border: 1px solid $black;
    border-radius: 50px;
    background: $black;
    color: $black;
}
.landing-page #block-4 a.enter:hover {
    border: 1px solid $black;
    background: $black;
    color: $light;
}
.landing {
    display: flex;
}

/*
Comment
*/

.comments {
    position: absolute;
    bottom: 0px;
    left: 0;
    display: flex;
    flex-direction: row;
}
.comments svg{
    max-width: 20px;
    margin-right: 5px;
}
.content-box:hover .thumbnail-content .comments svg path{
    fill: $light;
}

/*
Video
*/

.video {
    float: left;
    height: 100%;
    position: relative;
    display: block;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0px;
}
.content-container > span.video {
    display: block;
    height: 100%;
    width: 500px;
}
#block-1 .video {
    padding: 0px;
    display: grid;
}
:root {
    --plyr-color-main: $black;
}
.video .plyr__poster {
    background-size: cover;
    background-position: top;
}

/*
Components
*/

.edition {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 40px;
    right: 5px;
    opacity: .2;
}
.hero-image{
    position: relative;
    z-index: 1;
}
.hero-image img{
    max-height: 40vh;
    object-fit: cover;
}
.post-info {
    position: sticky;
    top: 60px;
    height: calc(100vh - 90px);
    padding: 0px 0px 10px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: inherit;
    justify-content: space-between;
}
.post-info .four-columns {
    grid-template-columns: 1fr 1fr 1fr 2fr;
}
.item-title {
    padding-bottom: 20px;
}
.content-box a:hover{
    text-decoration: underline;
}
.page-title {
    padding: 10px 0px;
}
.page-title h2{
    margin-bottom: 0px;
}

/*
Background
*/

.light-background {
    background: #f4f2f0;
    z-index: 10;
    position: relative;
}

/*
About
*/
.about .primary-header {
    display: none;
}
.about main {
    padding: 0px;
}
.about .full-height {
    height: calc(100vh - 20px);
}
.introduction {
    background: $black;
    color: $black;
}
.about-content {
    border-bottom: 1px solid $lightAccentColor;

}
.about .flex-masonry figure{
    padding: 40px;
}
.about-panel {
    width: calc(50% - 0px);
    border-bottom: 1px solid $lightAccentColor;
}
.about-panel:nth-last-child(2),
.about-panel:last-child {
    border-bottom: none;
}
.about-panel h1{
    font-size: 4rem;
    line-height: 1;
    margin-bottom: 40px;
}
.about-number {
    font-family: $fontSuisse;
    padding: 20px;
}
.about .sessions-logo{
    padding-bottom: 20px;
}
/*Sessions*/
.sessions h1 {
    font-size: 11.5vw;
    letter-spacing: -10px;
    opacity: .1;
    line-height: 1;
    margin-top: 20px;
}
.sessions h2 {
    font-size: 3.5rem;
    letter-spacing: 0px;
    line-height: 1;
    opacity: 1;
    padding-bottom: 20px;
    color: $black;
}
.sessions sup {
    font-size: 3rem;
    font-family: 'Helvetica Neue';
    letter-spacing: 0px;
    margin-left: 20px;
    border: 1px solid $black;
    padding: 10px 0 0 0 ;
    border-radius: 100px;
}
.sessions-logo {
    max-width: 50vw;
    width: 100%;

}
.sessions-logo svg {
    width: 100%;
    object-fit: contain;
    height: auto;
    opacity: 1;
}
.sessions-logo path{
    fill:$black;
}
.sessions-logo rect {
    stroke: $black;
}

/*
Sponsors
*/

.sponsors {
    max-width: 600px;
    padding: 20px;
    align-items: center;
    margin-bottom: 30px;
}

/*
Gallery Grid
*/

.front {
    position: relative;
    z-index: 1;
    background: #f4f2f0;
}
.gallery-grid-container {
    display: grid;
    grid-template-columns:  1fr 2fr 1fr 2fr 2fr 1fr;
    grid-gap: 40px;
    height: calc(100vh - 60px);
    width: 100%;
    overflow: hidden;
    padding: 20px;
    position: fixed;
    top: 0px;
    z-index: 0;
    animation-fill-mode: forwards;
}
.gallery-item {
    width: 100%;
    height: 100%;
    animation: moveDown 50s infinite linear;
    -webkit-filter: blur(10px);
    filter: blur(10px);
    transition: 1s;
}
.gallery-item:hover {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    cursor: pointer;
}
.gallery-item img{
    object-fit: contain;
    padding: 40px;
}
.gallery-grid-container .gallery-item:nth-child(4n+1),
.gallery-grid-container .gallery-item:nth-child(4n+3) {
    animation-direction: normal;

}
.gallery-grid-container .gallery-item:nth-child(4n+2),
.gallery-grid-container .gallery-item:nth-child(4n+4) {
    animation-direction: reverse;
}
@keyframes moveDown {
    from {
        transform: translateY(0%);
    }
    to {
        transform: translateY(115%);
    }
}

/*
Page Navigation
*/

.post-nav {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 10px;
}
.post-nav a{
    padding-right: 10px;
    font-size: 24px;
    color: $black;
    opacity: .25;
}
.post-nav a:hover {
    opacity: 1;
}

/*
Journal
*/
.journal main a:hover{
    opacity: .5;
}
.large-quote {
    height: calc(100vh - 195px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: $fontSuisse;
    margin: 40px 0px;
    flex-direction: column;
}
.large-quote h2{
    display: none;
    margin-bottom: 10px;
}
.large-quote h1 {
    max-width: 1200px;
    font-size: 3rem;
}

.article-two-columns p a {
    font-family: $fontSuisse;
    text-decoration: underline;
}
.article-two-columns p a:hover {
    opacity: .5;
}
.article-two-columns ul li{
    margin:0px 20px 20px 30px;
    list-style: circle;
    margin-bottom: 20px;
    font-size: .875rem;
}
.article-two-columns {
    columns: 100px 2;
    padding-bottom: 20px;
}
.article-two-columns hr{
    margin-bottom: 20px;
}
.journal .article-two-columns:first-child {
    border-right: 1px solid $lightAccentColor;
    padding-right: 20px;
}
.article-two-columns:nth-child(2) {
    padding-left: 20px;
}
.article-four-columns {
    columns: 300px 4;
    column-gap: 20px;
    padding-top: 20px;
}
.journal .article-two-columns .page-title, .journal .article-four-columns .page-title{
    padding: 0px 0px 10px;
}
.article-details {
    display: flex;
    padding-bottom: 20px;
}
.article-details small:first-child {
    padding-left: 0px;
}
.article-details small{
    padding-left: 5px;
}
.featured-journal button{
    margin-top: 40px;
}
.large-article figure {
    height: fit-content;
    padding: 20px;
}
.featured-journal .featured-article small{
    padding-bottom: 30px;
    display: block;
}
.featured-journal .featured-article button{
    margin-top: 80px;
}
.featured-journal .featured-article {
    width: 90%;
}
.featured-article .article-details small:first-child {
    padding-right: 20px;
}

/*
Single Article
*/

main .container {
    margin-top: 20px;
}
.post-info .three-columns {
    grid-template-columns: 1fr 1fr 2fr;
}
.main-info.content-wrapper{
    padding: 0px 20px;
}

/*
Comments
*/

.single-page-comments a{
    color: $black;
    opacity: .5;
    text-decoration: underline;
}
.single-page-comments a:hover{
    opacity: 1;
    text-decoration: underline;
}
.single-page-comments h5{
    margin-bottom: 0px;
}
.add-comment label {
    margin: 20px 0px;
    display: block;
}
.add-comment textarea {
    padding: 10px;
    font-family: 'Helvetica Neue';
    font-size: 1rem;
}
.comment {
    border-left: 1px solid $lightAccentColor;
    padding-left: 20px;
    padding-bottom: 40px;
}
.comments-wrapper {
    padding-top: 40px;
}
.single-page-comments {
    display: flex;
    flex-direction: column;
}
.single-page-comments textarea  {
    border: none;
}
.single-page-comments input,
.single-page-comments textarea {
    width: 100%;
}
.comment .single-comment:first-child{
    padding-left: 10px;
    margin-left: 0px;
}
.single-comment{
    margin-bottom: 20px;
}
.comment-date {
    text-align: right;
}
.user {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}
.user img{
    max-width: 50px;
    padding-right: 10px;
}
.comment-content {
    display: block;
    padding-bottom: 20px
}
.comment-content p{
    margin-bottom: 0px;
}
.minimize-comment {
    opacity: .25;
    cursor: pointer;
}
.minimize-comment:hover {
    opacity: 1;
}
#reply {
    text-align: right;
}
#reply a{
    text-decoration: none;
}

/*
Research Space
*/

.research-space .cta .dark-button{
    display: inline-flex;
    padding: 20px 12px 12px;
    line-height: 10px;
    border-radius: 50px;
    margin-top: 0;
}
.research-space .research-subjects-wrapper .panel{
    padding:120px 60px;
}
/*
Single Research Space
*/

.title-panel {
    position: sticky;
    top: 100px;
    padding: 0px 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: inherit;
    justify-content: space-between;
    padding-top: 20px;
}
.title-panel h2{
    padding-top: 20px;
    max-width: 600px;
}
.panel {
    padding: 20px;
    border-bottom: 1px solid;
}

/* Research Subjects */

#research-subjects .content-wrapper{
    border-top: 1px solid $lightAccentColor;
}
.research-subjects-wrapper {
    border-top: 1px solid $lightAccentColor;
}
.full-width.content-wrapper{
    padding: 0px;
    border-bottom: 1px solid $lightAccentColor;
}
.invert-color {
    background: $black;
    color: $black;
}
.invert-color a {
    color: $black;
    opacity: .5;
}
.invert-color button a {
    color: $black;
    opacity: 1;
}
.invert-color button a:hover {
    text-decoration:none;
}
.invert-color a:hover {
    opacity: 1;
    text-decoration: underline;
}
.invert-color.main-panel {
    background: $black;
    color: $black;
    padding: 0px 20px;
}
.invert-color .container {
    padding: 0px 20px;
}
.invert-color .cat-container a {
    color: $black;
}
.invert-color .cat-flex button:hover a{
    color: $black;
}
.post-type {
    padding-bottom:40px;
    margin-bottom: 0px;
    border-bottom: 1px solid $lightAccentColor;
}
.post-type h1{
    margin-bottom: 20px;
}
.post-type .description {
    max-width: 600px;
}
/* Related Items */
.related-items {
    padding: 20px 0px;
    border-bottom: 1px solid $lightAccentColor;
}
/* References */
.references {
    padding:15px 20px;
    border-top: 1px solid $lightAccentColor;
    position: relative;
    display: block;
    z-index: 2;
    background:#f4f2f0;
}
.references .title{
    border-bottom: 1px solid $lightAccentColor;
    padding-bottom: 10px;
    margin-bottom: 20px;
}
.references ul li{
    padding: 5px 0px;
    margin-left: 10px;
    list-style-position: in;
    list-style: decimal
}
.references-filter {
    display: flex;
}
.references-filter .dropdown {
    width: 100%;
    display: grid;
}
.reference-title h4 {
    margin-bottom: .1rem;
}
.references-page .contribution {
    padding: 0px 0px 40px;
    border-bottom: 0px;
}

/*
Related Items
*/

.related-wrapper {
    padding-top: 40px;
    position: relative;

}
.related-research li{
    border-bottom: 1px solid $black;
    padding: 0px 0px 5px 0px;
    margin-top: 0px;
    line-height: 1;
}

/*
References
*/

.books{
    height: 620px;
    display: flex;
    padding: 10px 10px;
    overflow: hidden;
    position: relative;
    z-index: 0;
    margin-bottom: 10px;
}
.book.sideways {
    height: 600px;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
}
.book.rotate {
    transform-origin: 50% 50%;
    transform: rotate(11deg);
    right: 47px;
    position: relative;
}
.book.horizontal {
    height: 50px;
    width: 100%;
    align-self: flex-end;
    margin-right: 150px;
    margin-bottom: -5px;
}
.book {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: $black;
    border-radius: 10px;
    margin: 5px;
    cursor: pointer;
}
.book:hover {
    background: $black;
    color: $light;
}
.book small{
    padding: 0;
    margin: 0;
}
.cta-ref-wrapper {
    max-width: 500px;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    bottom: 0;
    margin: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.cta-ref {
    max-width: 500px;
    width: 100%;
}
.cta-ref h1{
    margin-bottom: 20px;
}
a.reference {
    color: $black;
}
.reference {
    border-bottom: 1px solid $lightAccentColor;
}
.reference:hover {
    background: $black;

}
.reference-title small{
    opacity: .30;
    margin-top: 5px;
    display: inline-block;
    padding: 0px 5px 0px 0px;
}
.external-link {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    opacity: 0;
}
.reference:hover .external-link {
    opacity: 1;
}
.external-link svg {
    max-width: 15px;
    height: auto;
}
.external-link small{
    padding-right: 20px;
}

/*
Community
*/

.cta-divider .content-wrapper:first-child{
    border-right: 1px solid $lightAccentColor;
}
.contributors-title h2{
    margin: 0;
    margin-top: 24px;
    line-height: 1;
}
.users-directory {
    padding-top: 20px;
}
.users-directory .item{
    border-top: 1px solid $lightAccentColor;
    padding-top: 20px;
}
.users-directory .user img{
    width: 80px;
    object-fit: contain;
    object-position: top;
}
.users-directory .user-info{
}

/*
Contributor
*/

.prof-wrapper {
    padding: 20px 0px;
}
.prof-wrapper a.button:hover{
    text-decoration: none;
}
.prof-info {
    padding-top: 40px;
}
.contribution {
    padding: 0px 0px 40px;
    border-bottom: 1px solid $lightAccentColor;
}
.contribution h4{
    margin:5px 0px;
}
.contribution a{
    opacity: .5;

    color: $black;
}
.contribution a:hover{
    text-decoration: underline;
}

/* Contributor Forms */

.contribution-form main .contribution .grid {
    grid-template-columns: 1fr 2fr 1fr
}

.contribution-form .form-item label{
    font-weight: bold;
}

.contribution-form .form-item .checkbox-container label{
    font-weight: normal;
}

.contribution-form textarea {
    min-height: 300px;
    border: 1px solid $lightAccentColor;
}
.contributor-page .contribution{
    margin-top: 20px;
}

/*
Forum
*/

body.forum {
    background: $black;
    color: $black;
}

/*
Search
*/

body.search{
    background: $black;
    color: $black;
}
body.search .primary-header {
    background: $black;
    color: $black;
}
body.search .header-left-panel {
    background: $black;
    color: $black;
    border-right: 1px solid $darkAccentColor;
}
body.search .header-left-panel a {
    background: $black;
    color: $black;
}
body.search main .search-bar {
    position: relative;
    bottom: inherit;
    color: $black;
    z-index: 0;
}
.result {
    border-bottom: 1px solid $darkAccentColor;
}
.result h2 {
    margin: 0;
}

/*
Table
*/

.table {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;

}
.table .row {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr 2fr 1fr;
    grid-gap: 20px;
    border-bottom: 1px solid $darkAccentColor;
}
.archive .filter {
    grid-template-columns: 2fr 1fr 2fr 2fr 1fr;
    grid-gap: 20px;
    padding: 20px 0px;
}
.archive .filter div{
    padding: 0px 20px;

}
.table .cell {
    align-content: first baseline;
    padding: 10px 20px;
    font-size: 1rem;
}
.table .cell.label {
    margin-bottom: 20px;
}
.table .labels {
    border-bottom: 0px;
    margin-top: 20px;
    opacity: .25;
}
.archive .table .row {
    border-bottom: 1px solid $lightAccentColor;
}
.archive .table .dark-button{
    margin-top: 5px;
    margin-right: 5px;
    display: inline-block;
}
/*
Credits
*/

.credits h5{
    margin-bottom: 1rem;
    font-family: $fontSuisse;
    font-size: 16px;
    opacity: 1;
}
.credits small{
    margin-bottom: .25rem;
    display: block;
}
.credits .item {
    padding-bottom: 40px;
    display: block;
}

/*
Sitemap
*/

.site-map main h5{
    opacity: .25;
}

@media screen and (max-width: 1440px) {

    .large-quote h1 {
        max-width: 1200px;
        font-size: 3rem;
    }
    .journal h1 {
        font-size: 4rem;
    }
    .journal .large-quote {
        height: calc(100vh - 160px);
    }
}
@media screen and (max-width: 1280px) {
    /*Landing Page*/
    .landing-page #block-4 .content-container {
        width: 450px;
    }
    .landing-page #block-4 a.enter, .landing-page #block-4 a.active {
        padding: 8px 10px 2px;
    }

    .post-info {
        position: relative;
        top: 0px;
    }

    /*Home Page*/
    .logo{
        width: 100vw;
        font-size: 32px;
    }

    /*Journal*/

    .journal .article-two-columns {
        column-gap: 20px;
        padding-bottom: 0px;
    }
    figure {
        padding: 0px;
    }
    figcaption {
        margin-bottom: 20px;
    }
    .journal .article-two-columns:first-child {
        border-right: none;
    }
    .journal .article-two-columns:nth-child(2) {
        padding:20px 0px;
    }
}
@media screen and (max-width: 1024px) {

    /*Height*/
    .full-height {
        height: auto;
        padding: 80px 20px;
    }
    .about .full-height {
        padding: 0px;
    }

    .divider {
        display: none;
    }

    .flex-masonry {
        flex-direction: column;
    }

    /* About */
    .about-panel {
        width: 100%;
    }
    .about .about-introduction div {
        padding: 40px;
    }
    .about .about-introduction h4 {
        padding: 20px;
    }
    .about .full-height {
        height: auto;
    }
    .about .full-height.about-introduction {
        height: calc(100svh - 30px);
    }
    .about .about-introduction div:first-child{
        justify-content: flex-start;
    }

    .about-introduction div:nth-child(2){
        padding: 20px;
        align-items: flex-end;
    }
    .about .about-panel {
        padding: 20px;
    }
    .about h4 {
        font-size: 1rem;
    }
    .about .padding-60 {
        padding: 0px 60px;
    }
    .about figcaption {
        margin-bottom: 0px;
    }
    .about figure {
        margin: 60px 0px;
    }
    .about-number {
        text-align: center;
    }
    .about .sponsors.gap-20{
        grid-gap: 0px;
    }
    .about .sponsors p{
        margin-bottom: 0px;
        font-size: .875rem;
    }
    .about .sponsors img{
        max-width: 200px;
        margin-bottom: 40px;
    }
    .post-info {
        height: calc(100svh - 100px);
    }

}
@media screen and (max-width: 768px) {
    /* Home */

    /*Landing Page*/
    .landing-page #block-4 .content-container {
        width: auto;
    }
    .landing-page #block-4 a.enter, .landing-page #block-4 a.active {
        padding: 8px 10px 2px;
    }
    .landing-page #block-2 .content-container {
        padding-right: 20px;
    }
    .landing.sessions svg {
        width: 85vh;
    }
    /*Table*/
    .table .row {
        grid-template-columns: 1fr 1fr ;
        border-bottom: 1px solid  $darkAccentColor;
        padding: 20px 0px;
    }
    .table .row:first-of-type {
        display: none;
    }
    .table .cell.label {
        display: none;
    }
    .table .cell {
        border: none;
    }
    .account-button {
        right: 90px;
    }

    .gap-40 {
        grid-gap: 10px;
    }
    .three-columns {
        grid-template-columns: 1fr;
    }
    .four-columns {
        grid-template-columns: 1fr;
    }
    .phi-sessions {
        max-width: 80%;
    }

    .page-title {
        padding-top: 0px;
        position: relative;
        top: 0px;
    }
    .item-title {
        padding-top: 10px;
        padding-bottom: 5px;
    }

    .contribution-form main .contribution .grid {
        grid-template-columns:  1fr
    }
    .main-info.content-wrapper {
        padding: 20px;
    }

    .large-quote h1 {
        font-size: 1.5rem;
    }

    .about .default-header {
        text-align: left;
    }
    .research-space h2 {
        font-size: 1rem;
    }
    .list li{
        font-size: 1rem;
        padding-left: 20px;
    }
    .books {
        z-index: -1;
    }
    .cta-ref-wrapper {
        padding: 20px;
        position: relative;
        height: auto;
    }
    .book.horizontal {
        display: none;
    }
    .book.rotate {
        display: none;
    }
    /*Home*/

    .home-form .form-title {
        padding-bottom: 15px;
    }

    /*Journal*/
    .large-quote {
        height: auto;
    }
    .journal .article-two-columns:first-child {
        border-right: none;
        padding-right: 0px;
    }
    .large-article.flex-grid {
        flex-direction: column;
    }
    .large-article img{
        padding-top: 40px;
    }
    .article-two-columns {
        columns: 100px 1;
    }
    .single-article main, single-research main, .journal main{
        overflow-x: hidden;
    }
    .gallery-grid-container {
        display: none;
    }
}
@media screen and (max-width: 600px) {
    .forum h1 {
        font-size: 1.5rem;
        text-align: center;
        padding-top: 20px;
    }
    .forum h1 {
        font-size: 1.5rem;
    }
    .padding-40 {
        padding: 20px;
    }
    .research-space .subjects h4 {
        font-size: 1rem;
    }
    .research-space .subjects h2 {
        font-size: 1.5rem;
    }
    .research-space .description h3 {
        font-size: 1rem;
    }
    .filter.two-columns {
        grid-template-columns: 1fr 1fr;
    }
    /*Table*/
    .table .row {
        grid-template-columns: 1fr;

        padding: 20px 0px;
    }
    .table .cell {
        padding: 0px 20px;
    }
    /*Landing*/
    .default-page .post-info h1 {
        font-size: 1.5rem;
        text-align: center;
    }
    /* Home */

    .about .sessions-logo {
        max-width: 70vw;
        padding: 0;
        padding-top: 40px;
    }
    .about-panel h1 {
        font-size:2rem ;
        padding: 0px;
        margin: 20px 0px;
    }
    .sessions h1 {
        font-size: 20vw;
        letter-spacing: -5px;
    }
    .about-panel {
        border-bottom: 0px;
    }
    .about .sessions h2 {
        font-size: 2rem;
        padding-top: 20px;
    }
    .sessions h2 {
        font-size: 3rem;
        padding-top: 20px;
    }
    .about .flex-masonry figure {
        padding: 10px;
    }
    .sponsors p{
        font-size: 1.5rem;
    }
    .sponsors img {
        max-width: 300px;
        padding: 0;
        margin: 0;
    }
    .journal .large-quote.default-header{
        min-height: auto;
    }
    .journal .article-two-columns{
        margin-bottom: 40px;
    }
    .journal .large-quote h1{
        font-size: 1rem;
        padding: 0px;
    }
    .journal .article-details {
        justify-content: left;
    }
    .journal .page-title{
        text-align: left;
    }
    .large-quote h2{
        display:block;
        margin-bottom: 10px;
    }
    .journal .default-header.large-quote {
        height: auto;
        margin-top: 0px;
        margin: 0px;
        text-align: left;
    }

    .primary-header .header-left-panel a {
        width: 80%;
    }

    .single-research .page-title{
        padding-top: 30px;
    }
    .content-wrapper {
        padding: 20px;
    }
    .large-quote {
        margin: 10px 0px;
    }
    .large-quote small{
        margin-bottom:20px;
    }
    .post-info {
        height: auto;
        border-bottom: 1px solid $lightAccentColor;
    }
    .about .large-quote {
        text-align: left;
        margin: 0;
    }
    .about .large-quote h1 {
        font-size: 1rem;
    }
    .research-space .large-quote h1 {
        font-size: 1rem;
        text-align: left;
    }
    .research-space .large-quote {
        margin: 0;
    }
    .journal .large-quote {
        height: auto;
    }
    .journal h2, .research-space h2 {
        font-size: 1.5rem;
    }
    .journal h4 {
        font-size: 1.25rem;
    }
    .dropdown select, .dropdown button {
        font-size: 1rem;
    }
    .dropdown {
        flex-direction: column;
        align-items: flex-start;
    }
    .filter {
        padding: 40px 20px;
    }
    .dropdown select {
        padding-bottom: 20px;
    }
    #references {
        padding-top: 80px;
    }
    .invert-color.main-panel {
        padding-top: 20px;
    }
    .post-info .three-columns {
        grid-template-columns: 1fr;
    }
    #research-subjects .main-panel{
        padding-bottom: 30px;
        height: 40vh;
    }
    .cta .cta-panel{
        height: 50vh;
        font-weight: 2rem;
        border-top: 1px solid $lightAccentColor;
    }
    .cta .cta-panel .page-title h2{
        font-size: 2rem;
        text-align: center;
    }
    .cta-ref-wrapper  {
        transform: initial;
        left: 0;
        top: 0;
    }
    .references-page .item{
        padding-top: 10px;
    }
    .books {
        height: auto;
        display: flex;
        padding: 10px 10px;
        overflow: hidden;
        position: relative;
        z-index: 2;
        margin-bottom: 10px;
        flex-direction: column;
        border-radius: 5px;
    }
    .book.sideways {
        writing-mode: sideways-lr;
        height: auto;
        writing-mode: inherit;
        transform: none;
        padding: 10px;
    }
    .book small {
        padding-right: 5px;
        line-height: 1rem;
        font-size: 0.85rem;
    }
    .comment .two-columns{
        grid-template-columns: 1fr 1fr;
    }

}
